import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const JobsPage: React.FC = () => (
    <Layout>
        <SEO title="Jobs" />

        <section className="section">
            <div className="container content">
                <h1 className="title is-1">Jobs</h1>

                <p>
                    A jobs page is coming soon. To post a job (free), please{" "}
                    <Link to="/contact/">contact us</Link>.
                </p>
            </div>
        </section>
    </Layout>
);

export default JobsPage;
